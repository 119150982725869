import React from "react";
import styled from "styled-components";
import { withPage } from "../../Page";
import AppConfig from "../../Config";
import { StaticFluidImage } from "../../Components/StaticImage";

class HomePage extends React.Component {
  render() {
    let config = AppConfig["Templates/LandingPage"];
    return (
      <Wrapper>
        <div className="banner">
          <StaticFluidImage path="home_banner.jpg" />
          <div className="banner-text">
            <h1>Revtel CMS</h1>
            <h3>powered by revteltech</h3>
          </div>
        </div>

        <div className="banner1">
          <h1>Do you want to see some cool stuff?</h1>
          <h2>Just scroll the page!</h2>
          <h3>And watch out!</h3>
        </div>
        <section>
          <div className="greeting1" data-delighter="start:0.5;end:0.95;">
            Here you are!
          </div>
          <div className="greeting2" data-delighter="start:0.5;end:0.95;">
            Here you are, again!
          </div>
        </section>
        <div className="banner2">
          <h1>{"That's it for today"}</h1>
          <h3>See you another day!</h3>
        </div>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background-color: lightgray;

  & > section {
    position: relative;
    overflow-x: hidden;
  }

  & > .banner {
    background-color: lightgray;
    position: relative;

    .banner-text {
      position: absolute;
      bottom: 30px;
      right: 30px;
      color: white;
    }
  }

  & > .banner1 {
    padding: 50px 20px;
    text-align: left;

    h3 {
      color: grey;
    }
  }

  & > .banner2 {
    padding: 50px 20px;
    text-align: right;

    h3 {
      color: grey;
    }
  }

  .greeting1 {
    position: relative;
    padding: 60px 20px;
    background-color: pink;
    font-size: 36px;
    text-align: left;
  }

  .greeting1.delighter {
    transition: all 0.3s ease-out;
    transform: translateX(-100%);
    opacity: 0.3;
  }

  .greeting1.delighter.started {
    transform: none;
    opacity: 1;
  }

  .greeting1.delighter.started.ended {
    color: white;
  }

  .greeting2 {
    position: relative;
    padding: 60px 20px;
    background-color: tomato;
    font-size: 36px;
    text-align: right;
  }

  .greeting2.delighter {
    transition: all 0.3s ease-out;
    transform: translateX(100%);
    opacity: 0.3;
  }

  .greeting2.delighter.started {
    transform: none;
    opacity: 1;
  }

  .greeting2.delighter.started.ended {
    color: white;
  }

  ${AppConfig["Templates/LandingPage"].css || ""};
`;

export default withPage(HomePage);
